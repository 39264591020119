.Navigation-Bar li {
  float: left;
  display: block;
  padding: 14px 16px;
}

a {
  // color: #007bff;
  color: #17a2b8;
  // color: #fed136;
  text-decoration: none;
  font-size: 25px;
}

/*
a:visited {
  color: turquoise;
}
*/

a:hover {
  color: orange !important;
}

.active {
  color: red !important;
}

ul li {
  list-style-type: none;
}

.Tipps-Icon {
  border: 3.5px solid;
  font-size: 2.2rem;
  // padding-top: 0.15rem;
  line-height: 1.5;
  text-align: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0px auto;
  margin-bottom: 10px;
  margin-top: -5px;
}
