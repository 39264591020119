.Infected-Table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.Infected-Table td,
th {
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.Infected-Table tr:nth-child(even) {
  background-color: #000000;
}
