.Eintrag {
  font-size: 20px;
}

.Erledigt {
  text-decoration: line-through;
}

.Zu {
  position: relative;
}

.Arrow {
  display: inline-block;
  position: absolute;
  top: 5px;
  margin-left: -7px;
  border: 12px solid transparent;
  border-right: 12px solid rgb(150, 150, 150);
}

.Bottom {
  top: 11px;
  margin-left: 1px;
  border: 12px solid transparent;
  border-top: 12px solid rgb(150, 150, 150);
}
.hide {
  display: none;
}

.Block {
  display: none;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: left;
  /*visibility: hidden; */
  /*display: none;*/
}

.Visible {
  display: block;
}

.ButtonAvailable {
  background-color: lightgreen;
  border-radius: 5px;
  display: inline-block;
  margin-left: 32px;
  margin-bottom: 4px;
  width: 175px;
}

.not {
  background-color: lightblue;
  border-radius: 5px;
  display: inline-block;
  margin-left: 32px;
  margin-bottom: 4px;
  width: 175px;
}

.buttonDelete {
  background-color: lightcoral;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: -20px;
}

.hide {
  visibility: hidden;
}

.descriptionPicture {
  width: 100%;
  border-radius: 10000px;
  border: 5px solid rgb(255, 255, 255);
}

.supplies-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(245, 245, 245);
  color: black;

  width: 80%;
  max-width: 960px;
  position: relative;
}

.supplies-heading {
  margin-bottom: 0px;
}

.todoDescription {
  border: 2px solid rgb(235, 235, 235);
  background-color: rgb(255, 255, 255);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 3px;
  font-size: 90%;
  color: rgb(117, 117, 117);
  font-weight: lighter;
  position: relative;
}

.todoDescription::before,
.todoDescription::after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 50%;
}

.todoDescription::before {
  left: -30px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid rgb(235, 235, 235);
}

.todoDescription::after {
  content: "";

  top: 50%;
  left: -26px;
  margin-top: -13px;
  border: 13px solid transparent;
  border-right: 13px solid rgb(255, 255, 255);
}
.Eintrag-label {
  padding-right: 15px;
}
