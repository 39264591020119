.contact {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-methods a {
  color: darkblue;
  font-size: 150%;
}
.contact-icon {
  display: inline-block;
  width: 35px;
  text-align: center;
  height: 3em;
  padding-top: 15px;
}

.contact-input {
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #6c757d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #5a6268;
}
