@import "~bootstrap/scss/bootstrap.scss";
@import "~video-react/styles/scss/video-react";

h2 {
  margin: 15px;
}
p {
  font-size: 20px;

  line-height: 1.2em;
  text-shadow: 0px 0px 2px white;
}

.App-header {
  background-color: #24272c;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.page-header {
  background-color: #24272c;
}

.wilkommen-page-header {
  background-image: url("../img/covid-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 75vh;
}
.transparent {
  opacity: 0.6;
}
.black {
  background-color: #282c34;
}
.white {
  color: white;
}

.Tipps-Nav {
}

.cont {
  background-color: #f5f5f5;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Arrow {
  display: inline;
}

.supplies-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(245, 245, 245);
  color: black;
  margin: auto;
  width: 80%;
  max-width: 960px;
  position: relative;
  text-align: center;
}

.page-header {
  display: flex;
  background-color: #24272c;
  justify-content: center;
}

.footer {
  height: 4vh;
}
